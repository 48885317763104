@font-face {
    font-family: 'Foco';
    src: url("https://cdn1.staticpanvel.com.br/cdn_service/fonts/Foco_W_Rg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Foco Light';
    src: url("https://cdn1.staticpanvel.com.br/cdn_service/fonts/FOCOLIGHT.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
  font-family: 'Foco';
  src: url("https://cdn1.staticpanvel.com.br/cdn_service/fonts/Foco_W_Bd.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
