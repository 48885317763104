@import 'fonts';

* {
  box-sizing: border-box;
}

input {
  touch-action: manipulation;
}

html,
body {
  height: 100%;
  background-color: var(--white-200);
}

body {
  margin: 0;
  font-family: Foco, sans-serif;
}

img {
  max-width: 100%;
}

a.details {
  text-decoration: none;
}

@media screen and (max-width: 959px) {
  .p-footer-menus .container {
    padding-left: 25px;
    padding-right: 25px;
    width: auto !important;
  }
}

.button-kit {
  width: 95% !important;
  height: inherit !important;
}

.base {
  height: calc(100vh - 218px) !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --white-100: #ffffff;
  --white-200: #f1f1f1;
  --white-400: #dfdfdf;
  --white-500: #eaeaea;
  --white-600: #eceeee;
  --white-1400: #e1e4e9;

  --gray-100: #bbbbbb;
  --gray-200: #bebebe;
  --gray-1300: #31363F;
  --gray-1400: #484E5B;
  --gray-1500: #606B80;

  --blue-200: #16b9f6;
  --blue-300: #606b80;
  --blue-500: #4163a6;
  --blue-700: #143c8d;
  --blue-900: #1a223f;
  --blue-1000: #0f1453;
  --blue-1200: #919aac;

  --black-100: #000000;
  --black-200: #1b1d22;

  --yellow-100: #fff001;

  --box-100: 0 4px 16px #7c83a559;
  --box-200: -1px 5px 7px 0px rgba(112, 112, 112, 0.41);
  --box-black-100: 0 0 15px rgba(0, 0, 0, 0.1);
  --box-black-200: 0 5px 14px rgba(0, 0, 0, 0.1);
  --colors-background: #F9FAFB;
}

.LIGHT {
  --panvel-2: var(--white-100);
  --panvel-7: var(--gray-1300);
  --panvel-8: var(--blue-1000);
  --panvel-9: var(--white-600);
  --panvel-12: var(--blue-700);
  --panvel-77: var(--gray-900);
  --panvel-content-showcase-title-after-before: var(--white-400);
  --panvel-shipping-bar-section: var(--white-500);
  --panvel-story-mobile-icon: var(--gray-100);
  --panvel-showcase-story-mobile: var(--gray-900);
  --panvel-topbar-description: var(--blue-300);
  --panvel-info-bar-titles-hover: var(--blue-200);
  --panvel-info-bar-box-shadow: var(--box-100);
  --panvel-showcase-story-mobile-card: var(--white-100);
  --panvel-showcase-story-mobile-card-box-shadow: var(--box-200);
  --panvel-cookies-background: var(--white-500);
  --panvel-cookies-border: var(--gray-200);
  --panvel-text-cookies: var(--blue-900);
  --panvel-text-cookies-info-privacy: var(--blue-900);
  --panvel-info-bar-item-subtitle: var(--blue-500);
  --panvel-info-bar-dot-actived: var(--gray-1500);
  --panvel-home-svg: url('https://cdn1.staticpanvel.com.br/cdn_service/svg/home-images/home-sprite-light-v3.svg');
  --panvel-background: var(--colors-background);
}

.DARK {
  --panvel-2: var(--black-200);
  --panvel-7: var(--white-100);
  --panvel-8: var(--white-1400);
  --panvel-9: var(--black-200);
  --panvel-12: var(--yellow-100);
  --panvel-77: var(--blue-1200);
  --panvel-content-showcase-title-after-before: var(--blue-300);
  --panvel-shipping-bar-section: var(--white-500);
  --panvel-story-mobile-icon: var(--gray-100);
  --panvel-showcase-story-mobile: var(--yellow-100);
  --panvel-topbar-description: var(--blue-300);
  --panvel-info-bar-titles-hover: var(--white-100);
  --panvel-info-bar-box-shadow: var(--box-black-100);
  --panvel-showcase-story-mobile-card: var(--black-200);
  --panvel-showcase-story-mobile-card-box-shadow: var(--box-black-200);
  --panvel-cookies-background: var(--gray-1400);
  --panvel-cookies-border: var(--blue-300);
  --panvel-text-cookies: var(--white-100);
  --panvel-text-cookies-info-privacy: var(--yellow-100);
  --panvel-info-bar-item-subtitle: var(--white-1400);
  --panvel-info-bar-dot-actived: var(--yellow-100);
  --panvel-home-svg: url('https://cdn1.staticpanvel.com.br/cdn_service/svg/home-images/dark/home-sprite-dark-v3.svg');
  --panvel-background: var(--black-100);
}
